import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import CaseStudyList from '../components/CaseStudyList'
import BgVideo from '../components/BgVideo';
import Hero from '../components/Hero';
import Testimonials from '../components/Testimonials';

export const IndexPageTemplate = ({
  title,
  intro,
  videoFallback,
  video,
  subheading,
  content,
  contentComponent,
  caseStudies,
  testimonials,
  brands
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <BgVideo src={video} fallback={videoFallback}/>

      <section>
          <div className="cover-container">
              <Hero>
                  <h2 className="h1">{title}</h2>
                  <p>{intro}</p>
                  <a href="/#what-i-do" className="button button--rev">Learn more <span className="icon-arrow-down"></span></a>
              </Hero>
          </div>

          <div id="what-i-do" className="section">
              <div className="section__inner">
                  <div className="container">
                      <div className="content-container content-container--long-form">
                          <h3>{subheading}</h3>
                          <PageContent className="content" content={content} />
                          <div className="button-group">
                              <Link className="button button--primary" to="/about/">About me <span className="icon-chevron-right"></span></Link>
                              <a href="/#work" className="button">My work <span className="icon-arrow-down"></span></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <div id="work" className="section section--window-video">
          <div className="section__inner">
              <section>
                  <h2 className="is-visually-hidden">Case studies</h2>
                  <CaseStudyList data={caseStudies}/>
              </section>

              <section>
                  <div className="container">
                      <h2>Others I have helped</h2>
                      <ul className="brand-list">
                          { brands.map( brand => (
                            <li key={brand}><img src={brand} alt="" width="260" height="150"/></li>
                          ))}
                      </ul>
                  </div>
              </section>
          </div>
      </div>

      <section className="section">
          <div className="section__inner">
              <div className="container">
                  <h2>What they&#8217;re saying</h2>
                  <Testimonials testimonials={testimonials}/>
              </div>
          </div>
      </section>
    </>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

const IndexPage = ({ data }) => {
  const { page, footerData, headerData } = data;
  const { frontmatter, fields, html } = page;
  const { image, video, brands, ...props} = frontmatter;

  return (
    <Layout footerData={footerData} headerData={headerData}>
      <IndexPageTemplate 
        {...props} 
        video={video.publicURL} 
        videoFallback={image.childImageSharp.fixed.src} 
        content={html}
        testimonials={fields.testimonials}
        caseStudies={data.caseStudies.edges}
        brands={brands.map( item => item.publicURL)}
        contentComponent={HTMLContent}
        />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const query = graphql`
  query IndexPageTemplate {
    page: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      fields {
        testimonials {
          html
          frontmatter {
            name
            citation
          }
        }
      }
      frontmatter {
        title
        intro
        subheading
        video {
          publicURL
        }
        image {
          childImageSharp {
            fixed(width: 1024, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        brands {
          publicURL
        }
      }
    }
    caseStudies: allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "case-study" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            image {
              publicURL
            }
            logo {
              publicURL
            }
          }
        }
      }
    }
    ...LayoutFragment
  }
`

export default IndexPage