import React from 'react';
import PropTypes from 'prop-types';

const BgVideo = props => {
    return (
        <div className="bg-video-container">
            <video src={props.src} autoPlay loop muted className="bg-video">
            </video>
        </div>
    )
}

BgVideo.propTypes = {
    
};

export default BgVideo;