import React from 'react';
import PropTypes from 'prop-types';

function sinDegrees(angle) {
    return Math.sin(angle/180*Math.PI);
}

const Hero = props => {
    const ref = React.useRef();
    const [styles, setStyles] = React.useState({});

    function setSize(){
        const angleHeight = (window.innerWidth + 200) * sinDegrees(5);

        setStyles({
            minHeight : window.innerHeight + 10 + angleHeight,
            paddingBottom : angleHeight
        });
    }

    React.useEffect(() => {
        if( typeof window !== 'undefined') {
            setSize();
            window.addEventListener('resize', setSize );
        }

        return () => {
            window.removeEventListener('resize', setSize );
        }
    }, []);

    return (
        <div className={props.className} id="intro" ref={ref} style={styles}>
            <div className={`${props.className}__inner`}>
                <div className={`${props.className}__content`}>
                    {props.children}
                </div>
            </div>
        </div>

    )
}

Hero.defaultProps = {
    className: 'hero'
}

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;