import React from 'react'
import PropTypes from 'prop-types'
import CaseStudyFragment from './CaseStudyFragment';

class CaseStudyList extends React.Component {
  render() {
    const { data } = this.props

    return (
        <ul className="case-study-list">
          {data && data.map(({ node: post }) => (
            <li className="case-study-list__item" key={post.id}>
                <CaseStudyFragment bgImg={post.frontmatter.image.publicURL} slug={post.fields.slug} title={post.frontmatter.title} logo={post.frontmatter.logo.publicURL}/>
            </li>
          ))}
      </ul>
    )
  }
}

CaseStudyList.propTypes = {
  data: PropTypes.array
}

export default CaseStudyList